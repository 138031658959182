<template>
  <div class="concent">
    <BaseForm
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      :type="type"
      class="formStyle"
      @handleFilter="newhandleFilter"
      @clearParams="clearParams"
      ref="fromdata"
    >
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        ></base-button>
      </div>
    </BaseForm>
    <Basetable
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :key="type"
      ref="tableData"
      :webPage="true"
      @pageIndexChange="pageIndexChange"
      @pageSizeChange="pageSizeChange"
      class="main-page-table"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <IconButton
          @click.prevent="jump(scope.row)"
          content="办理"
          icon="iconfont iconbanli"
        ></IconButton>
      </template>
    </Basetable>
  </div>
</template>
<script>
import { getDictLists } from '@/filters/fromDict'
import { progressApi } from '@/api/businessApi'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form'
import Basetable from '@/components/common/table/base-table/base-table'
import IconButton from '@/components/common/button/icon-button/icon-button'
import { fromconfig, columnsConfig } from './config.js'
import { workbenchApi } from '@/api/workbenchApi'
import { supplierApi } from '@/api/companyApi.js'
import Storage from '@/utils/storage'
export default {
  name: 'todo',
  components: { BaseForm, Basetable, IconButton, BaseButton },
  data () {
    return {
      fold: false,
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      type: 'DB',
      tableData: [],
      gysData: []
    }
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    from () {
      return fromconfig(this)
    },
    api () {
      return workbenchApi
    },
    ProcessNameList () {
      return getDictLists('PROCESS_NAME')
    }
  },
  methods: {
    jump (row) {
      const [obj] = this.gysData.filter(item => item.keyId === row.gysId)
      row.gysCompanyName = obj.enterpriseName
      if (row.actionUrl) {
        if (row.actionUrl.indexOf(';') !== -1) {
          row.actionUrl = row.actionUrl.split(';')[0]
        }
        if (row.actionUrl === '/business/resolutionReview/audit') {
          // 融资决议审核
          sessionStorage.setItem('examine', JSON.stringify(row))
          const params = {
            businessId: row.bizId,
            instanceId: row.instanceId,
            taskId: row.taskId
          }
          progressApi.updatestatus(params).then((res) => {
            if (res.success) {
              this.$router.push({ path: row.actionUrl, query: { type: 'E' } })
            }
          })
        } else {
          this.$router.push({
            path: row.actionUrl,
            query: {
              keyId: row.bizId,
              instanceId: row.instanceId,
              businessId: row.bizId
            }
          })
          Storage.setLocal('contractInfo', row)
          row.businessId = row.bizId
          sessionStorage.setItem('row', JSON.stringify(row))
          sessionStorage.setItem('examine', JSON.stringify(row))
        }
      }
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    // 时间处理
    getdate (val) {
      return (
        new Date(val).getFullYear() +
        '-' +
        (new Date(val).getMonth() + 1) +
        '-' +
        new Date(val).getDate()
      )
    },
    // 重置
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    // 带查询条件的查询
    newhandleFilter () {
      const params = {
        ...this.queryParas
      }
      params.pageIndex = 1
      this.queryParas.pageIndex = 1
      if (params.applicationdate) {
        params.startTime = this.getdate(params.applicationdate[0])
        params.endTime = this.getdate(params.applicationdate[1])
      }
      this.api.getTodo(params).then((res) => {
        if (res.data) {
          this.tableData = res.data.records
          this.$refs.tableData.total = res.data.total
        }
      })
    },
    // 查询
    handleFilter () {
      const params = {
        ...this.queryParas
      }
      if (params.applicationdate) {
        params.startTime = this.getdate(params.applicationdate[0])
        params.endTime = this.getdate(params.applicationdate[1])
      }
      this.api.getTodo(params).then((res) => {
        if (res.data) {
          this.tableData = res.data.records
          this.$refs.tableData.total = res.data.records.length ? res.data.total : 0
        }
      })
    },
    pageIndexChange (val) {
      this.queryParas.pageIndex = val
      this.handleFilter()
    },
    pageSizeChange (val) {
      this.queryParas.pageSize = val
      this.handleFilter()
    }
  },
  activated () {
    this.handleFilter()
    this.gysList()
  },
  mounted () {
    this.handleFilter()
    this.gysList()
  },
  created () {}
}
</script>
<style lang="scss" scoped>
.pd15 {
  padding: 15px;
}
.concent {
  height: calc(100% - 60px);
}
</style>
